
import { Col, Container, Image, Row } from 'react-bootstrap'
import styles from './Fotter.module.scss'
import { logoTTC } from '../../assets'

export const FooterPage = () => {
  return (
    <Row>
    <footer
      className={styles.footerPage}
    > 

      <Container>
        <Row className={`${styles.logo} h-100`}>
          <Col md={3} className='text-center'>
            <a href='https://www.ditpthinkthailand.com/' target='_blanck' aria-label='Department of International Trade Promotion, Ministry of Commerce, Thailand'>
            <Image src={logoTTC} fluid  alt='DITP logo - Think Thailand'/>
            </a>
            <Col className={styles.poweredBy}>
              <a href='http://www.apsis.com.ar' target='_blank' rel='noreferrer' aria-label='APSIS Comunicación y diseño'>
                   Powered by APSIS &copy; 
              </a>
            </Col>
          </Col>
        </Row>
      </Container>
    </footer>
    </Row>
  )
}
