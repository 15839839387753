import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Container, Image } from "react-bootstrap";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { LangSelector } from "../LangSelector";

import styles from "./EventDate.module.scss";
import { logoSheraton } from "../../assets";

export const EventDate = ({
  date = "Jun 29 2023",
  dateEs = " 29 de junio 2023",
}) => {
  return (
    <Row className={styles.eventDate}>
      <Col md={6} className={`${styles.dateNum}`}>
        <h1>
          <FontAwesomeIcon icon={faCalendar} shake size='2xl' />
        </h1>
      </Col>
      <Col md={6} className={`${styles.dateNum}`}>
        <Container fluid>
          <Row className='justify-content-center'>
            <Col>
              <h1>
                <LangSelector enText={date} esText={dateEs} />
              </h1>
            </Col>
          </Row>
          <Row className='pt-2 pb-2 justify-content-center'>
            <Col>
              <Image src={logoSheraton} fluid />
            </Col>
          </Row>
        </Container>
      </Col>
    </Row>
  );
};
