
import animationData from "../../assets/lotties/delivery-boy.json";

export const LottieScroll = () => {

  return (
    <div>Lottie</div>
  )
}

